import React, { useEffect, useRef, useState } from 'react'
import dynamic from 'next/dynamic';

import { HEALING_STORY_HEADING_HOME_PAGE, PRACTITIONER_SECTION, FAQ_HEADING_HOME_PAGE, HOW_IT_WORK_STEPS_HEADING, HOW_IT_WORK_STEPS } from 'constant/homepage';

import LoadingStatic from 'components/Loader/LoadingStatic';
import useWindowSize from 'customHook/useWindowSize';
import ConsultDoctorWrapper from '@/components/HomePage/ConsultDoctorWrapper';
import Loader from 'components/Loader/Loading';
import { getAuth } from 'services/identity.service';
import TopIconWrapper from '@/components/HomePage/TopIconWrapper';
import HowItWorkSteps from 'organisms/HowItWorkSteps';
import IndividualsSearchSection from 'organisms/IndividualsSearchSection';
import CommonHealthConcerns from '@/components/HomePage/commonHealthConcerns';
import HighlightBlueSection from '@/components/HomePage/HighlightBlueSection';
import { useRouter } from 'next/router';

const loading = () => <LoadingStatic className=' h-40' />;
const DiseaseBanners = dynamic(() => import('components/HomePage/DiseaseBanners'), { loading });
const ConcernAreaSection = dynamic(() => import('components/HomePage/ConcernAreaSection'), { loading });
const BlogSection = dynamic(() => import('components/HomePage/BlogSection'), { loading });
const PractitionerSection = dynamic(() => import('components/HomePage/PractitionerSection'), { loading });
const PartnerSection = dynamic(() => import('organisms/PartnerSection'), { loading });
const Faq = dynamic(() => import('organisms/Faq'), { loading });

const HomePage = ({ dashboard }) => {
    const auth = getAuth();
    const [windowWidth] = useWindowSize();
    const [isOpenConsultModal, setIsOpenConsultModal] = useState(false);
    const [consultDoctor, setConsultDoctor] = useState(null);
    const [doctor, setDoctor] = useState(null);
    const [isOpenAppointmentModal, setIsOpenAppointmentModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenDiseasesModal, setIsOpenDiseasesModal] = useState(false);
    const [concernArea, setConcernArea] = useState(null);
    const [openDiseaseModal, setOpenDiseaseModal] = useState({ doctorTag: '', isOpen: false })
    const searchBarRef = useRef(null);
    const router = useRouter();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (router.asPath.includes('#faq')) {
            const faqSection = document.getElementById('faq');
            if (faqSection) {
                faqSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [loading, router.asPath]);

    // Start: concern area 
    const onBackFromAppointment = () => {
        setIsOpenAppointmentModal(false);
        setIsOpenDiseasesModal(true);
    }

    const onDiseaseConcernRequestAppointment = (doctor) => {
        setDoctor({ ...doctor, isFromTopDoctorAppointment: true, type: 'CONCERN_AREA' });
        setIsOpenAppointmentModal(true)
        setIsOpenDiseasesModal(false);
    }
    // End: concern area 


    return (
        <div className="bg-basic-white">
            {isLoading && <Loader type='layer' className="h-114" />}
            {(windowWidth < 768 && windowWidth != 0 ) ? 
                <IndividualsSearchSection
                    className="flex justify-center w-screen py-2.75"
                    menuWidth={300}
                    ref={searchBarRef}
                />
            : null}
            <HighlightBlueSection
                className='bg-primary1-50 '
                title={dashboard?.topHeaderScrollingMarquee || 'Get Your Consultation Today - 20+ Specialities  |  20000+ Ayurveda Doctors  |  3000 Cities  |  2L+ Consultations'}
                onBtnText='Click Here'
                onSellClick={""}
            />
            <TopIconWrapper loading={loading} setLoading={setLoading} />
            <CommonHealthConcerns  setIsOpenDiseasesModal={setIsOpenDiseasesModal} concernAreaList={dashboard?.concern_area} setConcernArea={setConcernArea} auth={auth}/>

            <DiseaseBanners windowWidth={windowWidth} diseaseBanners={dashboard?.disease_banners}
                setOpenDiseaseModal={setOpenDiseaseModal} />

            <HowItWorkSteps className='bg-primary1-100 pt-4 pb-6 md:pt-8 md:pb-8 mb-3 mt-3' heading={HOW_IT_WORK_STEPS_HEADING} list={HOW_IT_WORK_STEPS} />

            <ConcernAreaSection setIsOpenDiseasesModal={setIsOpenDiseasesModal} concernAreaList={dashboard?.concern_area} setConcernArea={setConcernArea} />

            <BlogSection isHomeBlogs={true} />


            <PartnerSection className='mb-4 mt-8 md:mb-5' heading={HEALING_STORY_HEADING_HOME_PAGE}
                list={dashboard?.healing_story?.map(item => ({
                    id: item?.author,
                    name: item?.author,
                    description: item?.details,
                    avatar: process.env.NEXT_PUBLIC_PHP_BUCKET_URL + item?.profileImg,
                }))} theme='v2'
            />
            <Faq className='px-5 pt-6 pb-8.5 md:py-8 bg-gray-100' heading={FAQ_HEADING_HOME_PAGE} titleClass='text-12-14' list={dashboard?.faqs?.map(item => ({
                id: item?.question,
                title: item?.question,
                description: item?.answer,
            }))} theme='v2' />

            {!auth?.user?.id && <PractitionerSection
                className='px-5 py-7.5'
                // className='px-5 my-8.5 md:mt-12.5 md:mb-10 ' 
                heading={dashboard?.join_us?.title} list={dashboard?.join_us?.benefits}
                buttonText={PRACTITIONER_SECTION.button} windowWidth={windowWidth} />}

            <ConsultDoctorWrapper consultDoctor={consultDoctor} isOpenConsultModal={isOpenConsultModal} setIsOpenConsultModal={setIsOpenConsultModal}
                doctor={doctor} setDoctor={setDoctor} isOpenAppointmentModal={isOpenAppointmentModal} setIsOpenAppointmentModal={setIsOpenAppointmentModal}
                onBackFromAppointment={onBackFromAppointment} openDiseaseModal={openDiseaseModal} setOpenDiseaseModal={setOpenDiseaseModal}
                concernArea={concernArea} windowWidth={windowWidth} isOpenDiseasesModal={isOpenDiseasesModal}
                onDiseaseConcernRequestAppointment={onDiseaseConcernRequestAppointment} setIsOpenDiseasesModal={setIsOpenDiseasesModal} />

        </div>
    )
}

export default HomePage